<template>
  <div class="full-area vertically-centered great-wrapper">
    <div class="yet-another-wrapper">
      <VueGoogleMap
        v-if="loadedMap"
        ref="map"
        :center="center"
        :zoom="zoom"
        :options="options"
        style="width: 100%; height: 100%;"
      >
        <GmapMarker
          :position="markerPosition"
          :clickable="true"
          :draggable="true"
          @position_changed="onMarkerPositionChanged"
          @dragend="onMarkerDropped"
        />
      </VueGoogleMap>
    </div>
    <QuestionHeader :data="data" :no-question-title="true" />
    <div class="locate-wrapper">
      <!--<transition-group name="fade">-->
        <!--<em v-if="geoError" key="error">{{geoError}}</em>-->
        <!--<h2 v-if="geoActive" key="success">¡Listo!</h2>-->
      <div @click="setMarkerToCurrentLocation" v-if="geoActive">
        <Icons
          class="icon standoutx"
          icon="crosshairs-gps"
          size="48"
          :color="'rgba(93,36,102,0.9)'"
          key="icon"
        ></Icons>
        <h3>Ubícame</h3>
      </div>
      <!--</transition-group>-->
    </div>
    <!--
    <div class="geo-message-wrapper">
      <transition-group name="fade">
        <em v-if="geoError" key="error">{{geoError}}</em>
        <h2 v-if="geoActive" key="success">¡Listo!</h2>
      </transition-group>
    </div>
    -->
    <footer>
      <SkipQuestionButton
        :disable-submit-button="!markerDropped"
        :show-submit="markerDropped && userMovedPin"
        :align-left="true"
        v-on:skip="skipQuestion()"
        v-on:submit="submit()"
        :is-mandatory="data.fields.isMandatory"
      />
    </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  name: 'QRReader',
  transition: 'page',
  props: ['data'],
  components: {
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton'),
    Icons: () => import('Components/base/Icons'),
    VueGoogleMap: VueGoogleMaps.Map,
    GmapMarker: VueGoogleMaps.Marker,
  },
  data() {
    return {
      notAllowed: false,
      notReadableError: false,
      loadedMap: false,
      center: {
        lat: 4.627106647330482,
        lng: -74.08104114768355,
      },
      zoom: 12,
      markerPosition: {
        lat: 4.627106647330482,
        lng: -74.08104114768355,
      },
      options: {
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: true,
          restriction: {
            latLngBounds: {
              north: 4.8400,
              south: 4.4600,
              west: -74.2300,
              east: -73.9900,
            },
            strictBounds: true,
          },
          styles: [
            {
              featureType: 'poi',
              elementType: 'all',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.medical',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.place_of_worship',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.school',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi.sports_complex',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            },
          ]
        },
      pickedMarkerPosition: false,
      markerDropped: false,
      markerChangeTimeout: null,
      lastLoggedPosition: null,
      lastKnownGoodPosition: null, 
      dragEndTimeout: null,
      userMovedPin: false,
      geoError: '',
      geoActive: false,
      deviceId: null,
      devices: [],
      form: {
        type: 'pinToMap',
        identifier: this.data.fields.identifier,
        decoded: false,
        question: this.data.fields.name,
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null,
        },
      },
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    onMarkerPositionChanged(event) {
      this.userMovedPin = true;
      this.pickedMarkerPosition = false;
      const that = this;

      if (event && typeof event.lat === 'function' && typeof event.lng === 'function') {
        const lat = event.lat();
        const lng = event.lng();

        // Define bounds
        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(4.4600, -74.2300), // southwest corner
          new google.maps.LatLng(4.8400, -73.9900)  // northeast corner
        );

        if (!isNaN(lat) && !isNaN(lng) && this.userMovedPin && this.markerDropped) {
          const newPosition = { lat, lng };

          // Check if the new position is within bounds
          if (bounds.contains(new google.maps.LatLng(lat, lng))) {
            // console.log('Marker is inside bounds, updating last known good position');
            that.lastKnownGoodPosition = newPosition; // update the last known good position

            if (that.dragEndTimeout) {
              clearTimeout(that.dragEndTimeout);
            }

            that.dragEndTimeout = setTimeout(() => {
              if (!that.lastLoggedPosition || that.lastLoggedPosition.lat !== lat || that.lastLoggedPosition.lng !== lng) {
                that.markerPosition = newPosition;
                // console.log('Marker position changed to:', newPosition);
                that.lastLoggedPosition = newPosition;
              } else {
                // console.log('Position has not changed since the last log');
                that.pickedMarkerPosition = true;
              }
            }, 50);
          } else {
            // console.log('Marker is outside of bounds');
            if (that.lastKnownGoodPosition) {
              // console.log('Reverting to last known good position:', that.lastKnownGoodPosition);
              // Revert marker position to last known good position
              that.markerPosition = that.lastKnownGoodPosition; 

              // Update the map marker immediately without waiting for timeout
              clearTimeout(that.dragEndTimeout);
            }
          }
        } else {
          that.pickedMarkerPosition = false;
        }
      }
    },
    onMarkerDropped() {
      this.markerDropped = true;
    },
    isLocationWithinBoundaries(location) {
      const boundaries = this.options.restriction.latLngBounds;
      return (
        location.lat <= boundaries.north && 
        location.lat >= boundaries.south && 
        location.lng <= boundaries.east && 
        location.lng >= boundaries.west
      );
    },
    setMarkerToCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          if (this.isLocationWithinBoundaries(newLocation)) {
            this.markerPosition = newLocation;
            this.pickedMarkerPosition = true;
            this.$refs.map.$mapObject.setCenter(this.markerPosition);
            this.markerDropped = true;
          } else {
            this.$refs.map.$mapObject.setCenter(this.center);
          }
        },
        (error) => {
          console.error("Error obtaining geolocation", error);
          this.markerPosition = this.center;
          this.pickedMarkerPosition = false;
          this.$refs.map.$mapObject.setCenter(this.center);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    },
    handleLocationError(message) {
      this.geoError = message
    },
    submit() {
      if (this.validInput(this.markerPosition)) {
        this.form.selected = [this.markerPosition];
      }
    },
    skipQuestion() {
      this.fieldValue = 'S99';
      this.next();
    },
    next() {
      this.arrows = false;
      this.form.timestamps.finish = new Date();
      this.$emit('next', this.form);
    },
    validInput(value) {
      if (value && this.isLocationWithinBoundaries(value)) {
        this.form.timestamps.valid = new Date();
        this.form.timeToCompletion = 
          this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime();
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.gettingLocation = true
    this.lastKnownGoodPosition = this.center;
    this.markerDropped = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( (position) => {
        this.geoActive = true
        this.geoError = ''
        this.geolocation = {
          type: "Point",
          coordinates: [position.coords.longitude, position.coords.latitude] 
        };
        this.$store.commit('STORE_GEOLOCATION', { geo: this.geolocation })
      }, () => {
        this.handleLocationError(`Acepta compartir tu ubicación. En la configuración del sitio en tu navegador, habilita la ubicación para ${process.env.VUE_APP_BASE_URL} y refresca la página para poder continuar.`);
      });
    } else {
      this.handleLocationError('Su navegador no parece tener soporte de geolocalización. Intente con otro dispositivo.');
    }
    await Vue.use(VueGoogleMaps, {
      load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        libraries: 'places',
      },
    });
    this.loadedMap = true;
  },
  watch: {
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal[0])) {
        setTimeout(() => {
          this.next();
        }, 50);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.standoutx {
  animation: standOutx 0.4s ease-in-out forwards;
}
@keyframes standOutx {
  0% { transform: scale(1.05) rotate(40deg) }
  20% { transform: scale(0.77) rotate(0deg) }
  100% { transform: scale(1.05) rotate(0deg) }
}
.yet-another-wrapper {
  height: 50vh;
}
.google-map {
  width: 100%;
  height: 50vh;
}
.hidden {
  opacity: 0.0001;
}
footer {
  &.hidden {
    opacity: 0.0001;
  }
}

.feedback-wrapper {
  color: $text-main;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 60vh;
  position: absolute;
  z-index: 0;
  @include breakpoint($desktop) {
    transform: translateY(10vh);
  }
}
.number-input-wrapper {
  background: white;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-direction: row;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  align-items: flex-start;
  position: absolute;
  width: calc(100% - 1em);
  top: 0;
  margin-top: 75%;
  height: 100%;

  @include breakpoint($tablet) {
    background-color: #fcfcef;
  }
  @include breakpoint($desktop) {
    transform: translateY(12vh);
  }
}
.number-input {
  width: 18%;
  display: flex;
  input {
    color: $color-wrapper-emphasis;
    font-family: monospace;
    margin: 0 5px;
    text-align: center;
    line-height: 80px;
    font-size: 50px;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    width: 100%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: purple;
      box-shadow: 0 0 5px purple inset;
    }
    
    &::selection {
      background: transparent;
    }
  }
}
.not-allowed-block {
  z-index: 100;
  margin-top: 20%;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.decoded-no-url {
  position: absolute;
  margin-top: -10000px;
}
.scan-wrapper {
  height: 100vh;
  width: 100vw;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0;
  @include breakpoint($tablet) {
    background-color: #fcfcef;
  }
  @include breakpoint($desktop) {
    background-size: 40%;
    background-position: -15% 105%;
  }
}
.decoded-content {
  display: inline;
  overflow-wrap: break-word;
}
.qr-input-field {
  max-width: 95%;
}
.column-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  line-height: 1;
  margin-top: 0.5em;
}
.extra-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  transition: all 0.2s ease-in-out;
  background-color: white;
  opacity: 0.0001;
  z-index: -1;
  &.active {
    z-index: 100;
    opacity: 1;
  }
}
.full-area {
  overflow: hidden;
  position: relative;
  max-height: 100vh;
  height: 100vh;
  background: transparent !important;

  @include breakpoint($tablet) {
    max-width: 500px;
    margin: auto;
  }

  .overlay {
    opacity: 1;
    z-index: 20;
    position: absolute;
    height: 100vh;
    width: 100vw;
    transition: all 0.2s ease-in-out;
    @include breakpoint($desktop) {
      transform: translateY(12vh);
    }

    &.hidden {
      background: transparent;
      z-index: -1;
    }
  }
}
.protocol {
  color: rgba(white, 0.3);
}
.submit-button {
  transform: scale(1.3);
  display: inline;
  max-width: 45%;
  margin: auto;
  margin-top: 2em;
  min-width: 100px;
}
.instructions {
  background: rgba(#100014, 0.2);
}

.shakeIt {
  animation: shakeItMore 1.22s ease-in-out infinite forwards;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-delay: 1.2s;
}

@keyframes shakeItMore {
  0% { transform: rotate(0deg) scale(1); opacity: 0.9;}
  20% { transform: rotate(-0deg) scale(1.01); opacity: 1; }
  40% { transform: rotate(-0deg) scale(1); opacity: 0.9; }
  100% { transform: rotate(-0deg) scale(1); opacity: 0.9; }
}

</style>
<style lang="scss">
.qr-camera-wrapper {
  .wrapper {
    padding: 0 !important;
    background: none !important;
  }
}
.el-button--primary {
  border-color: $color-wrapper-emphasis !important;
  background-color: $color-wrapper-emphasis !important;
}
.qr-input-field {
  .el-textarea {
    font-size: 2em;
    font-family: monospace;
    margin-bottom: 0.5em;
  }
  .el-textarea__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
  .el-input__inner {
    border: 1px solid $color-emphasis;
    text-align: center !important;
    font-size: 2em;
    padding: 1em;
    font-family: monospace;
  }
  .el-input__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
}
.gm-style {
  & [role='dialog'],
  & .gm-style-iw-tc,
  img[alt="Google"],
  & [style*='z-index: 1000001'] {
  //& [style*='z-index: 1000000'] {
    display: none !important;
  }
}
</style>
<style scoped lang="scss">
.great-wrapper {
  justify-content: flex-start!important
}
h1 {
  color: $color-emphasis;
}

.wrapper {
  background: white !important;
}
.desktop-wrapper {
  box-shadow: none;
}
.locate-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // flex: 1;
  // justify-content: center;
  align-items: center;
  height: 20vh;
  bottom: 0;
  left: 0;
  right: 0;
  //margin-top: 5px;
  & > div {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    &:hover {
      background: #e0e0e0;
    }
    & > div {
      margin-right: 10px;
    }
  }
}
</style>